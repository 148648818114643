/*@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");*/

/*@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@600&display=swap');*/


/* GENOS: Brand Font (Theme ID: "mainlogo" ) */
@import url('https://fonts.googleapis.com/css2?family=Genos:wght@500&display=swap');


/* LATO: Content/Text Font (Theme ID: *DEFAULT* and all h1-h6 ) */
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');



/* Handwriting Fonts (Theme ID: "personal" ) */

/* CAVEAT: Very good, rather scripty and perhaps a little hard to read. */
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

/* PATRICK HAND: Much easier to read and not script at all. Like chalkboard but a little too regular, structure. */
/*@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Patrick+Hand&display=swap');*/

/* NOTHING YOU COULD DO: Graphically intense; scratchy lines, too much so. And actually hard to read. */
/*@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Nothing+You+Could+Do&family=Patrick+Hand&display=swap');*/

/*OOOH BABY: Hard to read.*/
/*@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');*/




html,
body,
#root,
.app {
    height: 100%;
    width: 100%;
    font-family: "Lato", sans-serif;
}

